import React from 'react';
import '../App.css'; // Ensure the CSS is imported

const Home = () => {

  return (
    <div>
      <div className="main-content">
        <h2>About Kippyscout</h2>
      </div>
    </div>
  );
};

export default Home;

